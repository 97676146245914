import React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import CallToAction from '../components/CallToAction/CallToAction'
import PhotoGallery from '../components/PhotoGallery/PhotoGallery'
import Clients from '../components/Clients/Clients'
import Hero from '../components/Hero/Hero'

const IndexPage = () => {
  return (
    <>
      <Seo title="Katia Ehlert" />
      <Layout>
        <Hero />
        <CallToAction header="Contact me" text="Get in touch" to="/contact" />
        <PhotoGallery />
        <Clients />
        <CallToAction header="Let's work together" text="Work with me" to="/contact" />
      </Layout>
    </>
  )
}

export default IndexPage