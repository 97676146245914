import React from 'react'
import Gallery from './Gallery'
import styled from 'styled-components'
import Button from '../ui/Button'

const GalleryContainer = styled.div`
  margin: 80px auto;
`

const GalleryInner = styled.div`
  text-align: center;
`

const ButtonGrid = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 80px auto;
  flex-direction: column;

  a {
    margin: 10px 20px 10px 20px;
  }

  @media screen and (min-width: 780px) {
    flex-direction: row;

    a {
      margin: 10px;
    }
    
  }
`

const PhotoGallery = () => {
  return (
    <GalleryContainer>
      <GalleryInner>
        <h3>Gallery</h3>
       
        <ButtonGrid>
          <Button to="/advertising" dark="true">Advertising</Button>
          <Button to="/filmography" dark="true">Filmography</Button>
        </ButtonGrid>
        <Gallery />
      </GalleryInner>
    </GalleryContainer>
  )
}

export default PhotoGallery
