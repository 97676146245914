import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const HeroContainer = styled.div`

  .background-image {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    min-height: 40vh;
    justify-content: center;
    align-items: center;
    color: #101010;
    background-size: cover;
    background-position: bottom;
  }

  @media screen and (min-width: 1080px) {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1080px;
  margin: 0 auto;

  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Title = styled.h1`
  text-align: left;
`

const TagLine = styled.p`
  font-size: 1.414rem;
  font-weight: 700;
  text-align: left;
  margin-top: 0;
`

const Hero = () => {
  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero background.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData = image.placeholderImage.childImageSharp.fluid

  return (
    <HeroContainer>
    <BackgroundImage
          Tag="section"
          fluid={imageData}
          className="background-image"
          style={{
            // Defaults are overwrite-able by setting one or each of the following:
            backgroundSize: 'contain',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
          }}
        >
      <Grid>
        <div>
          <Title>Makeup, Hair &amp; Nails</Title>
          <TagLine>Specializing in Film &amp; Advertising</TagLine>
        </div>

            <div></div>


    
      </Grid>
      </BackgroundImage>
    </HeroContainer>
  )
}

export default Hero
