import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Maximize, ChevronRight, ChevronLeft } from 'react-feather'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  max-width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 780px) {
    max-width: 1080px;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @media screen and (min-width: 1080px) {
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const LightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  cursor: pointer;

  img {
    width: 100%;
  }

  .gatsby-image-wrapper {
    max-width: 900px;
  }
`

const ImageWrapper = styled.div`
  position: relative;

  &:hover > div {
    opacity: 0.8;
  }
`

const ImageIcon = styled.div`
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px / 2);
  right: calc(50% - 50px / 2);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GatsbyImageStyled = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  img {
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.3s;
  }

  &:hover img {
    cursor: pointer;
    filter: blur(100px);
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.3s;
  }
`

const GatsbyImageOpen = styled(GatsbyImage)`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  z-index: 10;
`

const ClickSurfaceToClose = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`

const ChevronLeftStyled = styled(ChevronLeft)`
  position: fixed;
  top: 50%;
  left: 30px;
  z-index: 11;
  opacity: 0.4;
  &:hover,
  &:focus {
    opacity: 1;
    transform: scale(1.1);
  }
`

const ChevronRightStyled = styled(ChevronRight)`
  position: fixed;
  top: 50%;
  right: 30px;
  z-index: 11;
  opacity: 0.4;
  &:hover,
  &:focus {
    opacity: 1;
    transform: scale(1.1);
  }
`

const Gallery = (props) => {
  const images = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "gallery" }
          ext: { regex: "/(jpeg|jpg|gif|png)/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              id
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)

  const imageData = images.allFile.edges

  const [isOpen, setIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)

  const openImageHandler = (e) => {
    setIsOpen((isOpen) => !isOpen)

    const openedImage = imageData.filter(
      (image) => image.node.name === e.target.alt
    )
    setCurrentImage(openedImage[0])
  }

  const hideLightBoxHandler = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const previousImageHandler = (e) => {
    e.stopPropagation()

    let currentIndex = imageData.indexOf(currentImage)
    let lastImageIndex = imageData.length - 1

    if (currentIndex === 0) {
      let lastImage = imageData[lastImageIndex]

      setCurrentImage(lastImage)
    } else {
      let previousImage = imageData[currentIndex - 1]

      setCurrentImage(previousImage)
    }
  }

  const nextImageHandler = (e) => {
    e.stopPropagation()

    let currentIndex = imageData.indexOf(currentImage)
    let lastImageIndex = imageData.length - 1
    let firstImageIndex = 0

    if (currentIndex === lastImageIndex) {
      let firstImage = imageData[firstImageIndex]

      setCurrentImage(firstImage)
    } else {
      let nextImage = imageData[currentIndex + 1]

      setCurrentImage(nextImage)
    }
  }

  return (
    <>
      <Grid>
        {imageData.map((image) => {
          return (
            <ImageWrapper
              key={image.node.childImageSharp.id}
              onClick={openImageHandler}
            > 
              <GatsbyImageStyled
                image={image.node.childImageSharp.gatsbyImageData}
                alt={image.node.name}
                placeholder="blurred"
                layout="fluid"
              />
              <ImageIcon>
                <Maximize size="50px" color="#101010" />
              </ImageIcon>
            </ImageWrapper>
          )
        })}
      </Grid>
      {isOpen && (
        <>
          <LightBox>
            <ClickSurfaceToClose onClick={hideLightBoxHandler} />
            <GatsbyImageOpen
              onClick={hideLightBoxHandler}
              image={currentImage.node.childImageSharp.gatsbyImageData}
              alt={currentImage.node.name}
              placeholder="blurred"
              layout="fixed"
            />
            <ChevronLeftStyled
              onClick={previousImageHandler}
              color="#fff"
              size="40px"
            />
            <ChevronRightStyled
              onClick={nextImageHandler}
              color="#fff"
              size="40px"
            />
          </LightBox>
        </>
      )}
    </>
  )
}

export default Gallery
